.hover-effect {
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 50%;
  cursor: pointer;
  width: 100px;
  max-width: 100px;
}

.hover-effect:hover {
  opacity: 1;
  transform: scale(1.1);
}
